<template>
    <div>
        <ul class="flex flex-wrap justify-center -m-3 pl-0 list-none" :class="{ 'gallery2-wrap-before_after' : beforeAfter }">
            <li v-for="img,key in imgs" class="sample-wrap p-3 m-0">
                <a href="" @click.prevent="showLarge(key)" class="block sample-img-wrap">
                    <img :src="img.thumb" width="270" height="270" :alt="img.name" loading="lazy">
                </a>
            </li>
        </ul>

        <photoswipe v-if="open"
                    :images="pswpImages"
                    :start-index="startIndex"
                    @close="open = false"></photoswipe>
    </div>
</template>

<script>
import photoswipe from './photoswipe';
import { track } from '../track';

export default {
    props: {
        imgs: {
            type: Array,
            required: true,
        },
        beforeAfter: {
            type: Boolean,
            defualt: false
        },
        name: String,
    },

    data () {
        return {
            open: false,
            startIndex: 0,
        };
    },

    computed: {
        pswpImages () {
            const images = [];

            this.imgs.forEach((img) => {
                images.push({
                    src: img.large,
                    msrc: img.thumb,
                    title: img.name,
                    w: img.w,
                    h: img.h,
                });
            });

            return images;
        },
    },

    components: {
        photoswipe,
    },

    methods: {
        showLarge (key) {
            this.startIndex = key;
            this.open = true;

            track({
                category: 'gallery',
                action: 'view',
                label: 'location:' + window.location.pathname,
            });
        },
    },
}
</script>

export const track = ({ category, action, label }) => {
    if (typeof ga === 'function') {
        ga('send', 'event', category, action || 'click', label);
    }
};

export const trackDirective = {
    bind(el, { arg: event, value }) {
        el.addEventListener(event, () => {
            track(value);
        }, { once: true });
    }
};

import Vue from 'vue';
import fetch from 'unfetch';

import svg_icons from './component/svg_icons.vue';
import vinyls from './component/vinyls.vue';
import gallery from './component/gallery.vue';
import { trackDirective, track } from './track';

Vue.directive('track', trackDirective);

// SASS/CSS
import '../../css/public.scss';

// images
import '../../images/icons-public.svg';

// disable the warning about dev/prod
Vue.config.productionTip = false;

new Vue({
    el: '#app',

    components: {
        'svg-icons': svg_icons,
        'cwd-vinyls': vinyls,
        'cwd-gallery': gallery,
    },
    data: {
        show: false,
        vinylsJson: '/vinyls.json',
        vinyls: {},
    },

    beforeMount () {
        this.getVinylJson();
    },

    mounted() {
        this.$nextTick(() => {
            this.setupGaEvents();
            window.addEventListener('resize', this.windowResize);
        });
    },

    methods: {
        async getVinylJson () {
            const response = await fetch(this.vinylsJson);

            if (response.ok) {
                this.vinyls = await response.json();
            }
        },

        toggle (e) {
            this.show = !this.show;
            e.preventDefault();
        },

        windowResize () {
            this.show = false;
        },

        track,
        /**
         * Setup GA events that are done easier with a query selector.
         */
        setupGaEvents () {
            this.addGaEvent(this.getNodeListAsArray('a[href^=tel]'), 'click', (e) => {
                this.track({ category: 'phone_number', label: 'phone_number:' + e.target.innerText });
            });

            this.addGaEvent(this.getNodeListAsArray('a[href^=mailto]'), 'click',  (e) => {
                this.track({ category: 'email', label: 'email:'+e.target.innerText });
            });

            this.addGaEvent(this.getNodeListAsArray('a[href*="weatherdek.com"]'), 'click',  (e) => {
                this.track({ category: 'link', label: e.target.href });
            });
        },
        getNodeListAsArray (selector) {
            return Array.from(document.querySelectorAll(selector));
        },
        addGaEvent (els, event, callable) {
            els.forEach((el) => {
                el.addEventListener(event, callable);
            });
        },
    },
});

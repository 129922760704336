<template>
    <div>
        <ul class="flex flex-wrap justify-center items-center -m-3 pl-0 list-none"
            :class="{ 'hp-vinyl' : size === 'large' }">
            <li v-for="(dimensions,color) in colors" class="p-3 mb-0 hover:no-underline">
                <a :href="'#'+series+'-'+color | lower"
                   :title="color"
                   @click="showLarge(color)"
                   class="text-xl text-center font-thin tracking-wide hover:no-underline">
                    <div class="mb-1">{{ color }}</div>
                    <div class="sample-img-wrap">
                        <img :src="thumbImg(color)"
                             :alt="color"
                             :width="thumbWidthHeight"
                             :height="thumbWidthHeight"
                             loading="lazy"></div>
                </a>
            </li>
        </ul>

        <photoswipe v-if="open"
                    :images="pswpImages"
                    :start-index="startIndex"
                    @close="open = false" />
    </div>
</template>

<script>
import { findIndex } from 'lodash';
import photoswipe from './photoswipe';
import { track } from '../track';

export default {
    components: {
        photoswipe,
    },

    props: {
        series: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: 'small',
        },
    },

    data () {
        return {
            open: false,
            startIndex: 0,
        }
    },

    computed: {
        colors () {
            return this.$root.$data.vinyls[this.series];
        },
        thumbWidthHeight () {
            return this.size === 'large' ? 270 : 270;
        },

        pswpImages () {
            if (!this.colors) {
                return [];
            }

            const images = [];

            Object.entries(this.colors).forEach(([color, dimensions]) => {
                images.push({
                    src: this.largeImg(color),
                    msrc: this.thumbImg(color),
                    title: color,
                    w: dimensions.w,
                    h: dimensions.h,
                });
            });

            return images;
        },
    },

    filters: {
        lower (str) {
            return str.toLowerCase();
        },
    },

    methods: {
        thumbImg (color) {
            return '/images/vinyls/'+color.replace(/\s+/g, '-').toLowerCase()+'.jpg';
        },
        largeImg (color) {
            return '/images/vinyls/'+color.replace(/\s+/g, '-').toLowerCase()+'-lg.jpg';
        },

        showLarge (color) {
            this.startIndex = findIndex(this.pswpImages, ['title', color]);
            this.open = true;

            track({
                category: 'vinyl_view',
                label: 'style-color:' + color,
            });
        },
    },
}
</script>
y
